.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  color: white;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  font-size: calc(10px + 2vmin);
  margin-bottom: 20px;

}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  margin-bottom: 10px;
}

div.messagebox {
  float: right;
  width: 45%;
}

div.message {
  float: unset;
  width: 85%;
}

div.image {
  float: left;
  width: 45%;
}

div.image img{
  width: 85%;
}
div.img-compare img{
  width: 45%;
}

.footer {
  padding-top: 20px;
  font-size: small;
}
.footer img {
  margin: 5px;
}

a {
  color:#61dafb
}